export function formatAddress(address?: string): string {
  try {
    if (!address) {
      return "-";
    }

    const addressParts = address.split("\n");
    if (addressParts.length >= 3) {
      /* eslint-disable-next-line */
      const [name, street, location, ...rest] = addressParts;
      const country = rest.length > 0 ? rest.join(" ") : "";

      return [street, location, country].filter(Boolean).join("\\n");
    }
  } catch (error) {
    console.error("Error formatting address:", error);
  }

  return address ?? "-";
}
