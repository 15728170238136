import { useMediaQuery } from "react-responsive";
import { ReactNode, useMemo } from "react";
import dayjs from "dayjs";
import classNames from "classnames";

import Shipment from "../../../entities/Shipment";
import Title from "../../Common/Title";
import classes from "./ShipmentDetails.module.scss";
import ShipmentStatusDownloadButton from "../ShipmentStatusDownloadButton";
import { formatAddress } from "../../../utils/format";

export type ShipmentDetailsProps = {
  selectedShipment: Shipment;
};

const ShipmentDetails = ({ selectedShipment }: ShipmentDetailsProps) => {
  const {
    carrier,
    trackingNumber,
    expectedDelivery,
    shipAddress,
    shipmentName,
    status,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = selectedShipment!;

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });

  const isStorePickup = status === "12";

  const renderFormattedAddress = (address?: string) => {
    if (!address) {
      return "-";
    }
    const formattedAddress = formatAddress(address);
    return formattedAddress.split("\\n").map((line, index, array) => (
      /* eslint-disable-next-line */
      <span style={{ marginBottom: "6px" }} key={`${line}_${index}`}>
        {line}
        {index < array.length - 1 && <br />}
      </span>
    ));
  };

  const detailsColumns: { first: ReactNode; second: ReactNode } =
    useMemo(() => {
      const columns = [
        { label: "Carrier:", value: carrier },
        {
          label: isStorePickup ? "Pickup Number" : "Tracking number:",
          value: trackingNumber,
        },
        {
          label: "Expected delivery:",
          value: expectedDelivery
            ? dayjs(expectedDelivery).format("MMM DD, YYYY").toString()
            : undefined,
        },
        { label: "Shipping to:", value: shipmentName },
        {
          label: "Shipping address:",
          value: shipAddress,
          isAddress: true,
        },
      ];

      if (isStorePickup) {
        columns.splice(-2);
      }

      const noEmptyColumns = columns.filter((detail) => detail.value);

      const elementsColumn = noEmptyColumns.map((detail) => (
        <div
          key={`${detail.label}_${detail.value}`}
          className={classNames(
            classes.valueContainer,
            detail.isAddress && classes.addressContainer
          )}
        >
          <span className={classes.label}>{detail.label}</span>
          <span className={classes.value}>
            {detail.isAddress
              ? renderFormattedAddress(detail?.value)
              : detail.value}
          </span>
        </div>
      ));

      const half = Math.ceil(elementsColumn.length / 2);
      const firstHalf = elementsColumn.slice(0, half);
      const secondHalf = elementsColumn.slice(half, elementsColumn.length);

      return {
        first: <>{firstHalf}</>,
        second: <>{secondHalf}</>,
      };
    }, [
      carrier,
      trackingNumber,
      expectedDelivery,
      shipmentName,
      shipAddress,
      isStorePickup,
    ]);

  return (
    <>
      <div className={classes.titleWrapper}>
        <Title size="m" kind="h3">
          Package Details&nbsp;
        </Title>
        <ShipmentStatusDownloadButton selectedShipment={selectedShipment} />
      </div>
      <div className={classes.info}>
        {!isMobile ? (
          <>
            <div className={classes.column}>{detailsColumns.first}</div>
            <div className={classes.column}>{detailsColumns.second}</div>
          </>
        ) : (
          <>
            {detailsColumns.first}
            {detailsColumns.second}
          </>
        )}
      </div>
    </>
  );
};

export default ShipmentDetails;
